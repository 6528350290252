import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { getRGBAColorWithOpacity } from '../../utils/helpers'
import { ANTIGEN_LOOKUP_API } from '../../utils/const'
import { wrangleIg13Data } from '../../utils/helpers'
import { MediaQueries as Mq } from '../../styles/MediaQueries'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4rem;
  margin: 3rem -1.5rem;
`

const MiniChartContainer = styled.div`
  background-color: ${({ mean }) => (!mean ? `var(--paleBlue)` : '')};
  padding: 1rem 0rem 4rem 2rem;
  border-radius: 0.75rem;
  margin: 1rem 1.5rem 2rem 1.5rem;
  min-width: ${({ minWidth }) => minWidth};
  ${Mq.xs} {
    min-width: 300px;
  }
`

const ChartTitle = styled.div`
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: ${({ color }) => `var(--${color})`};
  max-width: 320px;
  height: 5rem;
`
const MiniChart = styled.figure`
  width: 100%;
  height: 300px;
  display: flex;
  position: relative;
  //background-color: pink;
`

const BarCol = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 80px;
  height: 100%;
  background-color: ${({ color }) => color};
  margin: 0 10px;
  :nth-child(1) {
    margin-left: 0;
  }
  justify-content: flex-end;
  align-items: center;
`
const Bar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${({ height }) => height};
  width: 100%;
  background-color: ${({ color }) => `var(--${color})`};
  opacity: 1;
  color: var(--white);
  font-weight: bold;
  //padding-top: 0.5rem;
`

const BarPercentage = styled.div`
  position: relative;
  top: ${({ value }) => (value >= 8 ? '8px' : '-30px')};
`
const BarLabelContainer = styled.div`
  position: absolute;
  height: 5rem;
  bottom: -6rem;
`
const BarLabel = styled.div`
  font-size: 1.1rem;
  font-weight: ${({ weight }) => weight};
  color: var(--darkGray);
  margin: 0 -0.65rem;
  text-align: center;
  line-height: 1.2;
`
// const Line = styled.div`
//   width: 100%;
//   height: ${({ height }) => height};
//   border-top: ${({ color }) => `3px solid var(--${color})`};
//   position: absolute;
//   left: 0;
//   bottom: 0;
// `
// const Label = styled.div`
//   font-weight: bold;
//   font-size: 1.1rem;
//   color: ${({ color }) => `var(--${color})`};
//   width: 100%;

//   text-align: right;
//   position: absolute;
//   top: ${({ position }) => position};
// `

export const ANTIGEN_COLORS_API = {
  BOP: 'darkBlue',
  DTP3: 'brightBlue',
  HEPB3: 'blue4',
  HIB3: 'blue5',
  HPVc: 'aqua',
  IPV1: 'lime',
  MCV1: 'medGreen',
  MCV2: 'rose',
  PCV3: 'purple',
  POL3: 'chartreuse',
  RCV1: 'yellow2',
  ROTAC: 'orange'
}
const SP41Chart = ({ regions, title }) => {
  const { t } = useTranslation()

  const sp41Data = wrangleIg13Data(regions)
  //find highest number of years of data in any chart
  const findLongestArray = (data) => {
    let max = 0
    Object.entries(sp41Data).forEach(([key, value]) => {
      if (value.length > max) max = value.length
    })
    return max
  }

  const calcMinWidth = () => {
    //return '400px'
    return Math.max(findLongestArray(sp41Data) * 100 + 50, 350) + 'px'
  }
  const order = [
    null,
    'DTPCV3',
    'HEPB3',
    'HIB3',
    'PRHPVC_F',
    'IPV1',
    'MCV1',
    'MCV2',
    'PCV3',
    'POL3',
    'RCV1',
    'ROTAC'
  ]
  const BASELINE_YEAR = 2019
  return (
    <Container>
      {order.map((key, i) => (
        <MiniChartContainer minWidth={calcMinWidth()} key={key} mean={i}>
          <ChartTitle color={ANTIGEN_COLORS_API[ANTIGEN_LOOKUP_API[key]]}>
            {t(`${ANTIGEN_LOOKUP_API[key]}-SP41`)}
          </ChartTitle>
          <MiniChart
            role="img"
            alt={`${t(
              'Bar chart showing percentage of protection - mean across 12 antigens - by year for'
            )} ${t(ANTIGEN_LOOKUP_API[key])} `}
          >
            {sp41Data[key].map((d, i) => (
              <BarCol
                key={i}
                color={getRGBAColorWithOpacity(
                  ANTIGEN_COLORS_API[ANTIGEN_LOOKUP_API[key]],
                  0.3
                )}
              >
                <Bar
                  height={`${d.VALUE_NUM > 0 ? d.VALUE_NUM : 0}%`}
                  color={ANTIGEN_COLORS_API[ANTIGEN_LOOKUP_API[key]]}
                >
                  <BarPercentage value={d.VALUE_NUM}>
                    {`${(d.VALUE_NUM > 0 ? d.VALUE_NUM : 0).toFixed()}%`}
                  </BarPercentage>
                </Bar>
                <BarLabelContainer>
                  {/* <BarLabel weight={'normal'}>{d.YEAR}</BarLabel> */}
                  {/* <BarLabel weight={'bold'}>{t('Baseline')}</BarLabel>} */}
                  <BarLabel
                    weight={d.YEAR === BASELINE_YEAR ? 'bold' : 'normal'}
                  >
                    {d.YEAR === BASELINE_YEAR ? t('Baseline') : d.YEAR}
                  </BarLabel>
                </BarLabelContainer>
              </BarCol>
            ))}
            {/* <Line height={'90%'} color={ANTIGEN_COLORS_API[key]}>
              <Label color={ANTIGEN_COLORS_API[key]} position={'-30px'}>
                {t('2030 target')}
              </Label>
              <Label color={ANTIGEN_COLORS_API[key]} position={'0px'}>
                90%
              </Label>
            </Line> */}
          </MiniChart>
        </MiniChartContainer>
      ))}
    </Container>
  )
}

export default SP41Chart
